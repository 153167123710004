import ApiService from "Services/apiService";
import fileDownload from "js-file-download";

const BizService = {
    requetCompanies: async (setCompanies) => {
        let requestOptions = {
            url: "/v2/companies",
            params: {
                page: 0,
                pageSize: 100,
                sort: "name:asc",
            },
        };

        // console.log("requetCompanies..");

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);
            setCompanies(r.content);
        });
    },

    requetThingHistory: async (companyId, page, pageSize, sort, setRows, setRowCount) => {
        let requestOptions = {
            url: "/v2/thing_history",
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
            },
        };

        if (companyId) {
            requestOptions.params["companyId"] = companyId;
        }

        // console.log("requetCompanies..");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            let mapped = r.content
                .filter((row) => row.baseMonth)
                .map((row) => {
                    const original = row.baseMonth;
                    const formatted = original.slice(0, 4) + "-" + original.slice(4, 6);
                    return {
                        id: row.company.id + ", " + row.baseMonth,
                        company: row.company.name,
                        baseMonth: formatted,
                        countThing: row.countThing,
                        countGateway: row.countGateway,
                    };
                });

            // console.log(mapped);
            // console.log(r.totalElements);

            setRows(mapped);
            setRowCount(r.totalElements);
        });
    },

    requestLastMonth: async (companyId, setLastMonth) => {
        let requestOptions = {
            url: "/v2/thing_history/last_month",
            params: {},
        };

        if (companyId) {
            requestOptions.params["companyId"] = companyId;
        }

        // console.log("requestLastMonth..");
        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (!r.message) {
                setLastMonth(r);
            } else {
                setLastMonth(null);
            }
        });
    },

    requestReport: async (sort, reportType, language) => {
        let sortStr = sort.map((s) => s.field + ":" + s.sort).join(",");

        let requestOptions = {
            url: `/v2/thing_history/report?page=0&pageSize=100&reportType=${reportType}&sort=${sortStr}&language=${language}`,
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDownload(response, `MonthlyStatistics.${reportType}`);
            })
            .catch((e) => console.error(e));
    },

    requetDatabseUsage: async (companyId, setTotalSize, setUsageSize, setRows) => {
        let requestOptions = {
            url: `/v2/companies/${companyId}/database_usage`,
        };

        return ApiService.getData(requestOptions).then(r => {
            // console.log(r);

            let totalSize = 0;
            let sizeByCompany = 0;
            let monthlyData = {};
            let totalSizeByMonth = 0;

            r.forEach(e => {
                totalSize += e.totalSize;
                sizeByCompany += e.sizeByCompany;

                // Process monthly data
                e.monthly.forEach(m => {
                    const key = `${m.year}-${m.month.toString().padStart(2, '0')}`;
                    if (!monthlyData[key]) {
                        monthlyData[key] = { 
                            id: key,
                            year: m.year, 
                            month: m.month, 
                            sizeByMonth: 0, 
                            rowCount: 0 
                        };
                    }
                    monthlyData[key].sizeByMonth += m.sizeByMonth;
                    monthlyData[key].rowCount += m.rowCount;
                    totalSizeByMonth += m.sizeByMonth;
                });
            });

            // console.log("Total size by month:", totalSizeByMonth);

            setTotalSize(totalSize);
            setUsageSize(sizeByCompany);
            setRows(Object.values(monthlyData).sort((a, b) => 
                b.year - a.year || b.month - a.month
            ));
        });
    }   
};

export default BizService;
