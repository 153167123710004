import React from 'react';
import IconButton from '@mui/material/IconButton'; 

export default function Help(params) {
    const handleClickOpen = () => {
        window.open("https://www.pilot-things.com/support/home", "_blank");
    };

    return (
        <IconButton aria-label="help" onClick={handleClickOpen} size="large">
            <i className="fa fa-info-circle" aria-hidden="true" style={{ color: 'white' }}></i>
        </IconButton>
    );
};
