import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { AttributeColumns } from "./AttributeColumns";
import AlarmService from "./AlarmService";
import SuperviotConstants from "../SuperviotConstants";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function AttributeGrid({ siteName, thingId, defaultTitle }) {
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "name", sort: "asc" }]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [columns, setColumns] = useState(AttributeColumns(t));
    const [lastActivityDate, setLastActivityDate] = useState(null);

    // const columns = BoilerColumns(t);

    useEffect(() => {
        const mounted = { current: true };

        AlarmService.requestThing(thingId).then((r) => {
            if (!mounted.current) return;
            
            if (r.lastActivityDate) {
                const baseTime = dayjs(r.lastActivityDate * 1000)
                    .subtract(1, 'day')
                    .startOf('day');

                setLastActivityDate(baseTime);
                AlarmService.requestData(t, thingId, setColumns, setRows, baseTime.unix());
            }
        });

        return () => {
            mounted.current = false;
        };
    }, [thingId, t]);

    const handleDateChange = (newDate) => {
        setLastActivityDate(newDate);
        AlarmService.requestData(t, thingId, setColumns, setRows, newDate.unix());
    };

    const handleSelectionModel = (model) => {
        // console.log("handleSelectionModel ...");
        // console.log(model);
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };
    return (
        <Box
            sx={{
                mt: 3,
                "& .super-app.RED": {
                    backgroundColor: SuperviotConstants.RED,
                    color: "#FFFFFF",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.ORANGE": {
                    backgroundColor: SuperviotConstants.ORANGE,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.YELLOW": {
                    backgroundColor: SuperviotConstants.YELLOW,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.GREEN": {
                    backgroundColor: SuperviotConstants.GREEN,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.BLUE": {
                    backgroundColor: SuperviotConstants.BLUE,
                    color: "#1a3e72",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
                "& .super-app.WHITE": {
                    backgroundColor: SuperviotConstants.WHITE,
                    color: "#000000",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                },
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <Typography variant="h6" gutterBottom component="div">
                        {siteName}
                    </Typography> */}
                    {defaultTitle && (
                        <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>
                            {t("superviot.alarms_threshold")}
                        </span>
                    )}
                    {!defaultTitle && <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{siteName}</span>}
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t("label.start")}
                            value={lastActivityDate}
                            onChange={handleDateChange}
                            format={i18n.language === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <DataGridPro
                        columns={columns}
                        rows={rows}
                        rowCount={rowCount}
                        disableColumnFilter
                        pagination={false}
                        paginationMode="server"
                        sortingMode="server"
                        sortModel={sort}
                        paginationModel={pagination}
                        onPaginationModelChange={setPagination}
                        // pageSizeOptions={[20, 50]}
                        onSortModelChange={setSort}
                        // style={{ height: "calc(100vh - 80px)" }}
                        // slots={{
                        //     toolbar: CustomToolbar,
                        //     loadingOverlay: LinearProgress,
                        // }}
                        checkboxSelection={false}
                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        onCellClick={handleCellClick}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                            // "& .MuiDataGrid-columnHeaderTitle": {
                            //     whiteSpace: 'wrap',
                            //     textOverflow: 'clip',
                            //     lineHeight: '1.2em',
                            // },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                whiteSpace: "normal",
                                lineHeight: "normal",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                height: "unset !important",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                maxHeight: "168px !important",
                            },
                            "& .MuiDataGrid-columnHeaderTitleContainer": {
                                justifyContent: "center",
                                display: "flex",
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        hideFooter={true}
                        disableColumnMenu={true}
                        columnHeaderHeight={60}
                        autoHeight={true}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
