import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomButton } from "../ui/CustomButton";
import Item from "../ui/Item";
import CustomStyle from "Components/CustomStyle";
import SiteService from "./SiteService";
import UserService from "Services/userService";
import CustomConfirmDialog from "../ui/CustomConfirmDialog";
import AddrAutoComplete from "Components/addr/AddrAutoComplete";
import CustomSnackbar from "Components/CustomSnackbar";

export default function SiteForm({ selectedSite, setReload, position }) {
    const { t, i18n } = useTranslation();

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [company, setCompany] = useState("");
    const [description, setDescription] = useState("");

    const [errName, setErrName] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [message, setmessage] = useState("");
    const [severity, setSeverity] = useState("info");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            // console.log("///////--------------");
            // console.log(u);
            setCompany(u.company);
        });

        // console.log("uef in selectedSite", selectedSite);

        if (selectedSite) {
            setId(selectedSite.id);
            setName(selectedSite.name);
            setAddress(selectedSite.address);
            setCity(selectedSite.city);
            setPostalCode(selectedSite.postalCode);
            setLatitude(selectedSite.latitude);
            setLongitude(selectedSite.longitude);
            setDescription(selectedSite.description);
        } else {
            setId("");
            setName("");
            setAddress("");
            setCity("");
            setPostalCode("");
            setLatitude("");
            setLongitude("");
            setDescription("");
        }
        
    }, [selectedSite, position]);

    useEffect(() => {
        // console.log("uef in position", position);
        if (position && Array.isArray(position) && position.length === 2) {
            setLatitude(position[0].toString());
            setLongitude(position[1].toString());
        }
    }, [position]);

    const handleSave = () => {
        let err = false;

        if (validator.isEmpty(name)) {
            setErrName(true);
            err = true;
        } else {
            setErrName(false);
        }

        if (err) {
            return;
        }

        if (id === "") {
            SiteService.create(name, address, city, postalCode, latitude, longitude, company, description).then(() => {
                setReload();
            });
        } else {
            SiteService.update(id, name, address, city, postalCode, latitude, longitude, description).then(() => {
                setReload();
            });
        }
    };

    const handleDelete = () => {
        SiteService.delete(id)
            .then(() => {
                setReload();
            })
            .catch((e) => {
                // console.log("e", e);
                handleSnackbar("error", e);
            });
    };

    const handleSnackbar = (severity, message) => {
        setSeverity(severity);
        setmessage(message);
        setOpenSnackBar(true);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ height: "70px" }}>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <CustomButton startIcon={<SaveIcon />} name="Save" callback={handleSave} />
                            <CustomButton startIcon={<DeleteIcon />} name="Delete" callback={() => setOpen(true)} />
                        </Stack>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Name*:
                            </Typography>
                            <TextField
                                required
                                name="txtName"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={errName ? true : false}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Address:
                            </Typography>
                            <AddrAutoComplete
                                addr={{ label: address }}
                                callback={(e) => {
                                    // console.log(e);
                                    if (e && e.label && e.label != null) {
                                        setAddress(e.label);

                                        // Commented out as we are using position from SiteMap
                                        // if (e.Geometry) {
                                        //     const p = e.Geometry.Point;
                                        //     setLongitude(p[0]);
                                        //     setLatitude(p[1]);
                                        // }

                                        if (e.PostalCode) setPostalCode(e.PostalCode);
                                        if (e.Municipality) setCity(e.Municipality);
                                    } else {
                                        setAddress("");
                                        setLongitude("");
                                        setLatitude("");
                                        setPostalCode("");
                                        setCity("");
                                    }
                                }}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                City:
                            </Typography>
                            <TextField
                                name="txtCity"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Postal code:
                            </Typography>
                            <TextField
                                name="txtPostalCode"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Latitude:
                            </Typography>
                            <TextField
                                type="number"
                                name="txtLatitude"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Longitude:
                            </Typography>
                            <TextField
                                type="number"
                                name="txtLongitude"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                    <Item>
                        <Stack spacing={2} direction={"row"}>
                            <Typography variant="body1" gutterBottom sx={{ width: 150, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                Description:
                            </Typography>
                            <TextField
                                type="text"
                                name="txtDescription"
                                fullWidth
                                margin="dense"
                                size="small"
                                multiline
                                rows={6}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Stack>
                    </Item>
                </Grid>
            </Grid>

            <CustomConfirmDialog
                open={open}
                setOpen={setOpen}
                title="Delete site"
                message={`Would you like to delete '${name}' site ? The site for all things related is initialized.`}
                callback={handleDelete}
            />

            <CustomSnackbar open={openSnackBar} setOpen={() => setOpenSnackBar(false)} message={message} severity={severity} />
        </Box>
    );
}
