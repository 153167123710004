import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SuperviotConstants from "../SuperviotConstants";
import TooltipSlider from "./TooltipSlider";
import "rc-slider/assets/index.css";
import UserConfirm from "./UserConfirm";
import TextField from "@mui/material/TextField";

export default function SliderO2({ name, marks, defaultValue, min = 0, max, step = 1, attributeId, callback }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState();
    const [tempValue, setTempValue] = useState();
    const [changedValue, setChangedValue] = useState();
    const [changedIndex, setChangedIndex] = useState(null);
    const [selectedValue, setSelectedValue] = useState();

    const trackColors = [
        { backgroundColor: SuperviotConstants.RED },
        { backgroundColor: SuperviotConstants.ORANGE },
        { backgroundColor: SuperviotConstants.YELLOW },
        { backgroundColor: SuperviotConstants.GREEN },
        { backgroundColor: SuperviotConstants.BLUE },
    ];

    useEffect(() => {
        if (marks) {
            setValue(Object.keys(marks));
            setTempValue(Object.keys(marks));
        } else {
            setValue(defaultValue);
            setTempValue(defaultValue);
        }
    }, [defaultValue, min, max, step, marks]);

    useEffect(() => {
        let k = Object.keys(marks);

        const arraysAreEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false;
            let sortedArr1 = [...arr1].sort();
            let sortedArr2 = [...arr2].sort();
            return sortedArr1.every((val, index) => val === sortedArr2[index]);
        };

        if (value && k && !arraysAreEqual(k, value)) {
            setValue(Object.keys(marks));
            setTempValue(Object.keys(marks));
        }
    }, [marks, value]);

    const handleChange = (tempValue) => {
        setTempValue(tempValue);
    };

    const handleChangeComplete = (tempValue) => {
        // Sort marks by keys
        const sortedMarks = Object.fromEntries(
            Object.entries(marks).sort(([a], [b]) => Number(a) - Number(b))
        );
        
        // Find both the changed value and its index
        const newChangedIndex = tempValue.findIndex((v) => sortedMarks[v] === undefined);
        const changed = tempValue[newChangedIndex];

        // Save index and value of the changed value, this will be used to update the value in TextField
        setChangedIndex(newChangedIndex);
        setChangedValue(changed);

        // Disable the confirmation dialog
        // setOpen(true);

        // Save the value instantly
        setValue(tempValue);
        setSelectedValue(changed);
        callback(attributeId, trackColors, tempValue, false);
    };

    const handleCallback = (isYes) => {
        if (isYes) {
            setValue(tempValue);
            callback(attributeId, trackColors, tempValue, false);
            setSelectedValue(changedValue);
        } else {
            setTempValue(Object.keys(value));
        }
    };

    return (
        <Box sx={{ mb: 5 }}>
            <Typography id="track-false-range-slider" gutterBottom>
                {name}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center" }}>
                <TooltipSlider
                    range
                    count={5}
                    marks={marks}
                    pushable
                    trackStyle={trackColors}
                    handleStyle={[
                        { backgroundColor: SuperviotConstants.DEFAULT },
                        { backgroundColor: SuperviotConstants.DEFAULT },
                        { backgroundColor: SuperviotConstants.DEFAULT },
                        { backgroundColor: SuperviotConstants.DEFAULT },
                        { backgroundColor: SuperviotConstants.DEFAULT },
                        { backgroundColor: SuperviotConstants.DEFAULT },
                    ]}
                    railStyle={{ backgroundColor: "black" }}
                    onChange={handleChange}
                    onChangeComplete={handleChangeComplete}
                    min={min}
                    max={max}
                    step={step}
                    value={tempValue}
                />

                <TextField
                    size="small"
                    label=""
                    value={selectedValue || ""}
                    type="number"
                    InputProps={{
                        readOnly: false,
                    }}
                    onChange={(e) => {
                        // Sort values
                        const newTempValue = [...tempValue];
                        const sortedTempValue = newTempValue.map(Number).sort((a, b) => a - b);

                        // Convert the string value to a number
                        const numValue = Number(e.target.value);
                        // Update the value at the changed index
                        sortedTempValue[changedIndex] = numValue;

                        setSelectedValue(numValue);
                        callback(attributeId, trackColors, sortedTempValue, false);
                    }}
                    sx={{ width: 120 }}
                />
            </Box>

            <UserConfirm open={open} setOpen={setOpen} changedValue={changedValue} callback={handleCallback} />
        </Box>
    );
}
