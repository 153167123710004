import ApiService from "Services/apiService";
import * as minio from "minio";

const MinioService = {
    getMinioConfig: async () => {
        try {
            const credentials = await ApiService.putData({
                url: `/v2/miniio_credentials`,
            });

            // console.log("credentials", credentials);
            return credentials;
        } catch (error) {
            console.error("Error fetching Minio config:", error);
            throw error;
        }
    },

    uploadDoc: async (key, file) => {
        try {
            const minioConfig = await MinioService.getMinioConfig();
            
            const minioClient = new minio.Client({
                endPoint: minioConfig.endPoint,
                port: minioConfig.port,
                useSSL: minioConfig.useSSL,
                accessKey: minioConfig.accessKey,
                secretKey: minioConfig.secretKey,
            });

            // console.log("key", key);
            // console.log("file", file);

            const bucketName = minioConfig.bucket;

            // Convert the file to a Buffer
            const arrayBuffer = await file.arrayBuffer();
            const buffer = Buffer.from(arrayBuffer);

            const result = await minioClient.putObject(bucketName, key, buffer, file.size, {
                'Content-Type': file.type
            });
            
            const fileUrl = `${minioConfig.useSSL ? 'https' : 'http'}://${minioConfig.endPoint}:${minioConfig.port}/${bucketName}/${key}`;

            // console.log("File upload result:", result);
            // console.log("fileUrl", fileUrl);
            
            return { Bucket: bucketName, Location: fileUrl  };
        } catch (error) {
            console.error("Error uploading file", error);
            throw error;
        }
    },

    deleteDoc: async (key) => {
        try {
            const minioConfig = await MinioService.getMinioConfig();
            
            const minioClient = new minio.Client({
                endPoint: minioConfig.endPoint,
                port: minioConfig.port,
                useSSL: minioConfig.useSSL,
                accessKey: minioConfig.accessKey,
                secretKey: minioConfig.secretKey,
            });

            const bucketName = minioConfig.bucket;
            await minioClient.removeObject(bucketName, key);
            
            return { key, bucket: bucketName };
        } catch (error) {
            console.error("Error deleting file", error);
            throw error;
        }
    },
};

export default MinioService;
