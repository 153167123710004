import React, { useState, useEffect, useCallback } from "react";
import { TextField, Typography, Grid } from "@mui/material";
import { CustomButton } from "Components/ui/CustomButton";
import EmailSettingService from "./EmailSettingService";
import UploadIcon from "@mui/icons-material/Upload";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import validator from "validator";
import { AppId } from "Components/applications/ApplicationData";
import CustomSnackbar from "Components/CustomSnackbar";
import ImportTool from "Routes/deviceExplorer/toolBar/ImportTool";
import { debounce } from "lodash";

export default function EmailSetting() {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [lastUsed, setLastUsed] = useState("");
    const [companyId, setCompanyId] = useState("");

    const [emailError, setEmailError] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openImport, setOpenImport] = useState(false);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);
            setCompanyId(u.companyId);
            EmailSettingService.findNotification(u.companyId, setEmail, setLastUsed);

            EmailSettingService.requestLastNotifications(undefined, setLastUsed, AppId.SUPERVIOT, i18n.language);
        });
    }, []);

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        // validateEmails(newEmail);

        debounceDesc(companyId, newEmail);
    };

    const debounceDesc = useCallback(
        debounce((companyId, newEmail) => {
            // Perform save operation here
            // console.log(newEmail)
            validateEmails(companyId, newEmail);
        }, 500),
        []
    );

    const validateEmails = (companyId, emailString) => {
        const emails = emailString
            .split(";")
            .map((e) => e.trim())
            .filter((e) => e !== "");
        const invalidEmails = emails.filter((e) => !validator.isEmail(e));

        if (invalidEmails.length > 0) {
            setEmailError(`Invalid email(s): ${invalidEmails.join(", ")}`);
        } else {
            setEmailError("");
            handleEmails(companyId, emailString);
        }
    };

    const handleEmails = (companyId, emailString) => {
        const emails = emailString
            .split(";")
            .map((e) => e.trim())
            .filter((e) => e !== "");
        const validEmails = emails.filter((e) => validator.isEmail(e));

        if (validEmails.length === emails.length) {
            EmailSettingService.updateNotification(companyId, validEmails);
        } else {
            setEmailError("Please correct the invalid email(s) before submitting.");
        }
    };

    return (
        <>
            <Grid container spacing={2} sx={{ padding: 2, maxWidth: "sm" }}>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">Email(s)</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder={t("superviot.email_placeholder")}
                            helperText={emailError || t("superviot.email_helper")}
                            error={!!emailError}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="subtitle1">{t("superviot.last_email")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{lastUsed}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <CustomButton name={t("btn.import")} callback={() => setOpenImport(true)} startIcon={<UploadIcon />} />
                </Grid>
            </Grid>
            <CustomSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={t("superviot.email_updated")} severity="info" />

            <ImportTool openImport={openImport} setOpenImport={setOpenImport} />
        </>
    );
}
