import ApiService from "Services/apiService";
import { v4 as uuidv4 } from "uuid";

const createCustomField = (name, value) => ({
    id: uuidv4(),
    label: name,
    name: name,
    type: "TEXT",
    value: value,
});

const AddStationService = {
    addStation: async (companyId, name, fullName, latitude, longitude) => {
        let requestOptions = {
            url: `/v2/things/station`,
            data: {
                companyId,
                name,
                fullName,
                latitude,
                longitude,
            },
        };

        requestOptions.data.customField = [
            createCustomField("WORST_COLOR", "ORANGE"),
            createCustomField("HL_NUMBER", "68987214051"),
            createCustomField("TX_FREQ", "04:00:00"),
            createCustomField("PAS_MESURE", "01:00:00"),
            createCustomField("CFG_TX_FREQ_GREEN_BLUE", "04:00"),
            createCustomField("CFG_PAS_MESURE_YELLOW", "00:30"),
            createCustomField("CFG_PAS_MESURE_GREEN_BLUE", "01:00"),
            createCustomField("CFG_PAS_MESURE_RED", "00:05"),
            createCustomField("CFG_PAS_MESURE_ORANGE", "00:15"),
            createCustomField("CFG_TX_FREQ_RED_ORANGE_YELLOW", "01:00"),
            createCustomField("CFG_SMS_ON", "false"),
            createCustomField("SUPERVIOT_ICON_POSITION", JSON.stringify({ x: 300, y: 230 })),
        ];

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default AddStationService;
