import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DetailService from "./DetailService";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export default function GatewayStats({ selectedThingId }) {
    const { t, i18n } = useTranslation();
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        DetailService.requestGatewayStats(selectedThingId).then((r) => {
            const mapped = [
                {
                    name: "Count",
                    data: r.map((item) => item.count),
                },
            ];

            const categories = r.map((item) => `SF${item.sf}`);

            setSeries(mapped);
            setCategories(categories);
        });
    }, [selectedThingId]);

    const options = {
        chart: {
            type: "column",
            height: 200,
            scrollablePlotArea: {
                minWidth: 200,
                scrollPositionX: 1,
            },
        },
        title: {
            text: t("de.gw_stats_title"),
        },
        xAxis: {
            categories: categories,
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,
        },
        series: series,
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        navigator: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ height: 200 }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
    );
}
