import RoleCheckBoxes from "./RoleCheckBoxes";
import CustomSwitch from "../../../components/ui/CustomSwitch";

export function UserListColumn(handleEnabled, t) {
    return [
        {
            field: "id",
            headerName: t("col.id"),
            filterable: false,
        },
        {
            field: "email",
            headerName: t("col.email"),
            flex: 1,
            editable: false,
            filterable: false,
        },
        {
            field: "role",
            headerName: t("col.role"),
            flex: 0.7,
            editable: true,
            sortable: false,
            renderCell: (params) => {
                return <RoleCheckBoxes username={params.row.username} role={params.row.role}></RoleCheckBoxes>;
            },
            filterable: false,
        },
        {
            field: "company",
            headerName: t("col.organization"),
            flex: 0.5,
            editable: false,
            renderCell: (params) => {
                return params.value;
            },
            sortable: false,
            filterable: true,
            type: "string",
        },
        {
            field: "username",
            headerName: t("col.username"),
            flex: 1,
            editable: false,
            filterable: false,
        },
        {
            field: "enabled",
            headerName: t("col.enable"),
            flex: 0.5,
            editable: false,
            renderCell: (params) => {
                // console.log("///-------------------params")
                // console.log(params)
                return (
                    <>
                        {/* <span style={{ marginRight: 5 }}>{t("col.enable")}</span> */}
                        <CustomSwitch checked={params.row.enabled} name={params.row.username} onChange={handleEnabled} />
                    </>
                );
            },
            sortable: false,
            filterable: false,
        },
        {
            field: "isSubCompany",
            headerName: "isSubCompany",
            flex: 1,
            editable: false,
            filterable: false,
        },
    ];
}
