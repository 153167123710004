import { useState, useEffect, useContext } from "react";
import DeleteButton from "./DeleteButton";
import SettingContext from "Providers/SettingContext";
import MeasurementService from "./MeasurementService";
import SensorDetailDialog from "./thingDetail/SensorDetailDialog";
import _ from "underscore";
import Coordinator from "./Coordinator";
import UtilService from "Services/utilService";
import ExportMenu from "./ExportMenu";
import LinearProgress from "@mui/material/LinearProgress";
import UserService from "../../../services/userService";
import MeasurementServiceV2 from "./MeasurementServiceV2";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

function createData(id, company, thing, timestamp, model, manufacturer, product, connectivityType, status, tags, site, attributes) {
    let formattedTimestamp = "";
    if (timestamp != undefined) {
        formattedTimestamp = UtilService.formateDate(timestamp * 1000);
    }

    var obj = { id, company, thing, timestamp: formattedTimestamp, model, manufacturer, product, connectivityType, status, tags, site };

    _.each(attributes, function (attr) {
        for (const [key, value] of Object.entries(attr)) {
            obj[key] = value;
        }
    });

    return obj;
}

export default function MeasurementView({ keyword, appId, callback }) {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);

    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "fixedName", sort: "asc" }]);
    const [columns, setColumns] = useState([]);
    const [allColumns, setAllColumns] = useState([]);
    // const [keyword, setKeyword] = useState(props.keyword);

    const [on, setOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const [selectedThingId, setSelectedThingId] = useState("");
    const [isOpenThingDetail, setIsOpenThingDetail] = useState(false);
    const [sortQuery, setSortQuery] = useState("");
    const [user, setUser] = useState({});
    const [refresh, setRefresh] = useState();

    const apiRef = useGridApiRef();

    useEffect(() => {
        Coordinator.removeUnusedElement();

        // console.log("uef in MeasurementView");
        setColumns(Coordinator.getFixeColumns(t));

        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u);
            setUser(u);

            if (u.isRootCompany) {
                if (u.authorities == "USER") {
                    MeasurementServiceV2.requestByUser(keyword, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort, u.username)
                        .then((dynamicCols) => {
                            saveColumnVisibility(t, dynamicCols, setColumns, apiRef);
                        })
                        .catch((e) => console.error(e));
                } else {
                    // ADMIN
                    MeasurementServiceV2.requestData(keyword, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort)
                        .then((dynamicCols) => {
                            saveColumnVisibility(t, dynamicCols, setColumns, apiRef);
                        })
                        .catch((e) => console.error(e));
                }
            } else {
                const auth = u.authorities;

                if (auth == "USER") {
                    // USER, find assigned things by username
                    MeasurementServiceV2.requestByUser(keyword, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort, u.username)
                        .then((dynamicCols) => {
                            saveColumnVisibility(t, dynamicCols, setColumns, apiRef);
                        })
                        .catch((e) => console.error(e));
                } else {
                    // ADMIN,  find assinged things by comany
                    MeasurementServiceV2.requestByCompany(keyword, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort, u.company)
                        .then((dynamicCols) => {
                            saveColumnVisibility(t, dynamicCols, setColumns, apiRef);
                        })
                        .catch((e) => console.error(e));
                }
            }
        });
    }, [keyword, paginationModel.page, paginationModel.pageSize, on, setting, refresh, sort, t, apiRef]);

    const handleSortModelChange = (param) => {
        setSort(param);
    };

    const handleReport = (reportType) => {
        MeasurementService.requestReport(paginationModel.page, 1000, keyword, sortQuery, reportType);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport /> */}
                <ExportMenu callback={handleReport} />
                {/* <ToggleButton callback={handleToggle} on={on}></ToggleButton> */}
                <DeleteButton callback={() => setRefresh(moment())} selectionModel={selectionModel}></DeleteButton>
            </GridToolbarContainer>
        );
    }

    const handleSelectionModel = (selectionModel) => {
        setSelectionModel(selectionModel);
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
        setIsOpenThingDetail(true);
        setSelectedThingId(params.row.id);
    };

    const closeDialog = (isSaved) => {
        // console.log("closeDialog");
        setIsOpenThingDetail(false);
        callback();
        if (isSaved) {
            setRefresh(moment());
        }
    };

    const handleColumnOrderChange = (param) => {
        // console.log("handleColumnOrderChange....");
        Coordinator.saveColumnOrder({
            field: param.column.field,
            targetIndex: param.targetIndex,
        });
    };

    const handleColumnVisibilityChange = (model) => {
        // console.log("handleColumnVisibilityChange...")
        Coordinator.saveColumnVisibility(model);
    };

    return (
        <>
            <DataGridPro
                apiRef={apiRef}
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                disableColumnFilter
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                sortModel={sort}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[20, 50]}
                onSortModelChange={handleSortModelChange}
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                }}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                density="compact"
                onCellClick={handleCellClick}
                disableSelectionOnClick
                disableRowSelectionOnClick
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            company: false,
                            model: false,
                            manufacturer: false,
                            product: false,
                            connectivityType: false,
                            id_battery: false,
                        },
                    },
                }}
                onColumnOrderChange={handleColumnOrderChange}
                onColumnVisibilityModelChange={(model) => handleColumnVisibilityChange(model)}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
            />
            <SensorDetailDialog
                open={isOpenThingDetail}
                setOpen={(isSaved) => closeDialog(isSaved)}
                appId={appId}
                isAdmin={false}
                company={"PTH"}
                selectedThingId={selectedThingId}
                user={user}
            />
        </>
    );
}

function saveColumnVisibility(t, dynamicCols, setColumns, apiRef) {
    let fixedCols = Coordinator.getFixeColumns(t);
    let unitedCols = _.union(fixedCols, dynamicCols);

    let copiedColumns = [...unitedCols];
    const savedColumns = Coordinator.getColumnOrder();
    if (_.isEmpty(savedColumns)) {
        Coordinator.saveInitialOrder(copiedColumns);
    } else {
        // Reorder the columns base on savedColumns. if not style dosn't effect
        copiedColumns = savedColumns.map((s) => copiedColumns.find((c) => c.field === s.field)).filter((s) => s !== undefined);
    }

    setColumns(copiedColumns);

    let view_agg = copiedColumns.find((s) => s.field == "thing_tag_view.aggTag");
    if (!view_agg) {
        copiedColumns.push({
            field: "thing_tag_view.aggTag",
            headerName: "Tags",
            flex: 0.5,
            sortable: true,
        });

        Coordinator.saveInitialOrder(copiedColumns);
    }

    if (apiRef.current) {
        // Column Visibility
        const savedVisibility = Coordinator.getColumnVisibility();
        if (savedVisibility) {
            // console.log("//////////-------------")
            // console.log(unitedCols)
            for (const key in savedVisibility) {
                const value = savedVisibility[key];

                if (apiRef.current.getColumnHeaderElement(key)) {
                    apiRef.current.setColumnVisibility(key, value);
                }

                // becuase company has been change to 'company.name'
                if (key == "company") {
                    Coordinator.removeColumnVisibility();
                }
            }
        } else {
            // Set all dynamic columns into invisiable.
            dynamicCols.forEach((d) => {
                apiRef.current.setColumnVisibility(d.field, false);
            });
        }
    }
}
