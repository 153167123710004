import Checkbox from "@mui/material/Checkbox";

export function SubColumns(t, handleCheckBox) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "company",
            headerName: t("col.company"),
            flex: 0.7,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "startDate",
            headerName: t("col.start"),
            flex: 0.7,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "endDate",
            headerName: t("col.end"),
            flex: 0.7,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "autoRenewal",
            headerName: t("col.renewal"),
            flex: 0.7,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                // console.log(params);
                // if (!params.row.subscriptionId) {
                //     return <></>;
                // }

                return (
                    <Checkbox
                        inputProps={{ "aria-label": "Renweal" }}
                        checked={params.value}
                        onChange={(e) => handleCheckBox(params.id, e.target.checked)}
                    />
                );
            },
        },
        {
            field: "apps",
            headerName: t("col.apps"),
            flex: 1,
            editable: false,
            sortable: false,
            filterable: false,
        }
    ];
}
