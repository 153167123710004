import _ from "underscore";
import store from "store";
import CustomStatusChip from "../../../components/ui/CustomStatusChip";

// DE: Device Explorer
const DE_KEY_COLUMN_ORDER = "de.column.order";
const DE_KEY_COLUMN_VISIBILITY = "de.column.visibility";

const Coordinator = {
    getFixeColumns: (t) => {
        return [
            {
                field: "company.name",
                headerName: t("col.owner"),
                flex: 1,
            },
            {
                field: "fixedName",
                headerName: t("col.name"),
                flex: 1,
            },
            {
                field: "lastMessageDate",
                headerName: t("col.last_message"),
                flex: 1,
            },
            {
                field: "manufacturer",
                headerName: t("col.vendor"),
                flex: 1,
            },
            {
                field: "product",
                headerName: t("col.product"),
                flex: 1,
            },
            {
                field: "connectivityType",
                headerName: t("col.connectivity"),
                flex: 1,
            },
            {
                field: "status",
                headerName: t("col.status"),
                flex: 0.5,
                renderCell: (params) => {
                    return <CustomStatusChip status={params.value} />;
                },
            },
            {
                field: "thing_tag_view.aggTag",
                headerName: t("col.tags"),
                flex: 0.5,
                sortable: true,
            },
            {
                field: "site.name",
                headerName: t("col.site"),
                flex: 0.5,
                sortable: true,                
            },
        ];
    },

    getColumnOrder: () => {
        let savedColumns = store.get(DE_KEY_COLUMN_ORDER);
        return savedColumns ? savedColumns : [];
    },

    saveInitialOrder: (param) => {
        store.set(DE_KEY_COLUMN_ORDER, param);
    },

    saveColumnOrder: (param) => {
        let savedColumns = store.get(DE_KEY_COLUMN_ORDER);
        if (!savedColumns) {
            savedColumns = [];
        }

        // Move the element to param.targetIndex
        const tagsIndex = savedColumns.findIndex((r) => param.field === r.field);
        const tagsElement = savedColumns.splice(tagsIndex, 1)[0];
        savedColumns.splice(param.targetIndex - 1, 0, tagsElement);

        store.set(DE_KEY_COLUMN_ORDER, savedColumns);
    },

    getColumnVisibility: () => {
        let savedVisibility = store.get(DE_KEY_COLUMN_VISIBILITY);
        // console.log(savedVisibility);
        return savedVisibility;
    },

    saveColumnVisibility: (param) => {
        store.set(DE_KEY_COLUMN_VISIBILITY, param);
    },

    removeColumnVisibility: () => {
        store.remove(DE_KEY_COLUMN_VISIBILITY);
    },

    // If any field changes, it should be removed.
    // old version saves the 'company' filed but it is not exsited anymore
    removeUnusedElement: () => {
        let stored = store.get(DE_KEY_COLUMN_ORDER);
        if (stored) {
            const excludeUnused = stored.map((s) => {
                if (s.field == "company") {
                    s.field = "company.name";
                }
                return s;
            });
            store.set(DE_KEY_COLUMN_ORDER, excludeUnused);
        }
    },
};

export default Coordinator;
