import { useState, useEffect } from "react";
import GatewayStatus from "./GatewayStatus";
import Box from "@mui/material/Box";
import DetailService from "./DetailService";
import GatewayStats from "./GatewayStats";

export default function GatewayStatusPagination({ lastGatewayStatus, selectedThingId }) {
    const [gatewayStatus, setGatewayStatus] = useState([]);

    useEffect(() => {
        DetailService.requestGatewayStatus(selectedThingId).then((response) => {
            // console.log(response);
            setGatewayStatus(response);
        });
    }, [lastGatewayStatus, selectedThingId]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, overflowY: "scroll", height: 290 }}>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                {gatewayStatus.map((g, index) => {
                    return <GatewayStatus key={`${g.id}-${index}`} entity={g} />;
                })}
            </Box>

            {gatewayStatus && gatewayStatus.length > 0 && <GatewayStats selectedThingId={selectedThingId} />}
        </Box>
    );
}
