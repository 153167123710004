import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import UserService from "Services/userService";
import LogService from "./LogService";
import { LogColumns } from "./LogColumns";
import { frFR } from "@mui/x-data-grid/locales";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function LogMain({ defaultTitle, thingId }) {
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "entryDate", sort: "desc" }]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        // console.log("useEffect in LogMain");
        UserService.getAuthenticatedUser().then((u) => {
            if (u.authorities === "USER") {
                // Request the things of the user
                MeasurementServiceV2.requestByUser("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }], u.username).then((r) => {
                    const thingIds = r.map((item) => item.id);
                    LogService.requestLogs(pagination.page, pagination.pageSize, setRowCount, sort, setRows, u.companyId, thingIds).then((r) => {});
                });
            } else {
                // Request all logs of the company
                LogService.requestLogs(pagination.page, pagination.pageSize, setRowCount, sort, setRows, u.companyId, [thingId]).then((r) => {});
            }
        });
    }, [pagination.page, pagination.pageSize, sort, defaultTitle, thingId]);

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    const columns = LogColumns(t);

    return (
        <Box sx={{ flexGrow: 1 }}>
            {defaultTitle && <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("superviot.logs")}</span>}
            <Grid container spacing={0}>
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    rowCount={rowCount}
                    disableColumnFilter
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sort}
                    paginationModel={pagination}
                    onPaginationModelChange={setPagination}
                    pageSizeOptions={[20, 50]}
                    onSortModelChange={setSort}
                    style={{ height: "calc(100vh - 80px)" }}
                    // slots={{
                    //     toolbar: CustomToolbar,
                    //     loadingOverlay: LinearProgress,
                    // }}
                    checkboxSelection
                    // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                    density="compact"
                    onCellClick={handleCellClick}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    sx={{
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: 2,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: 2,
                        },
                    }}
                    columnVisibilityModel={{
                        id: false,
                        type: false,
                    }}
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                />
            </Grid>
        </Box>
    );
}
