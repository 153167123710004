import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import AttributeGrid from "./AttributeGrid";
import AppContext from "Components/AppContext";
import UserService from "Services/userService";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";

export default function AlarmMain() {
    const [positions, setPositions] = useState();
    
    const ctx = useContext(AppContext);
    ctx.updateTitle("Superviot");

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            const requestFunction = user.authorities === "USER" 
                ? () => MeasurementServiceV2.requestByUser("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }], user.username)
                : () => MeasurementServiceV2.requestData("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }]);

            requestFunction().then((r) => {
                const mapped = r.map(item => ({
                    name: item.name,
                    thingId: item.id,
                }));
                setPositions(mapped);
            });
        });
    }, []);

    return (
        <Box sx={{ pt: 7, ml: 2, mr: 2, pb: 4 }}>
            {positions?.map((s) => (
                <AttributeGrid key={s.thingId} siteName={s.name} thingId={s.thingId} />
            ))}
        </Box>
    );
}
