import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import fileDOwnload from "js-file-download";

const SubService = {
    requestSub: async (page, pageSize, sort, setRowCount, companyId, setSub) => {
        let requestOptions = {
            url: `/v2/companies/subsidiary`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
            },
        };

        if (companyId) {
            requestOptions.params.companyId = companyId;
        }

        // console.log(requestOptions);

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            let result = r.content.map((e) => ({
                ...e,
                company: e.company.name,
                startDate: UtilService.formatYearMonthDay(e.startDate * 1000),
                endDate: UtilService.formatYearMonthDay(e.endDate * 1000),
                apps: e.subscriptionApp
                    .map((a) => a.replace("APPLICATION_", ""))
                    .map((a) => {
                        switch (a) {
                            case "TEMPERATURE_MONITORING":
                                return "TempMon";
                            case "DEVICE_EXPLORER":
                                return "Device Explorer";
                            case "SUPERVIOT":
                                return "Superviot";
                            default:
                                return a;
                        }
                    })
                    .join(","),
            }));

            setSub(result);
            setRowCount(r.totalElements);
        });
    },

    setAutoRenewal: async (subscriptionId, renewal) => {
        let requestOptions = {
            url: `/v2/companies/set_auto_renewal`,
            data: {
                id: subscriptionId,
                renewal: renewal,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result.code && result.code === "failure") {
            throw result.message;
        }

        return result;
    },

    requestReport: async (reportType, companyId) => {
        let requestOptions = {
            url:
                "/v2/companies/report_subsidiary?" +
                "page=0" +
                "&pageSize=100" +
                "&sort=startDate:asc" +
                "&appName=H2O" +
                `&reportType=${reportType}` +
                `&companyId=${companyId}`,
        };
        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDOwnload(response, `Subscriptions.${reportType}`);
            })
            .catch((e) => console.error(e));
    },

    addSubscription: async (selectedCompanyId, appId, start, end, appIds) => {
        let requestOptions = {
            url: `/v2/companies/subscription`,
            data: {
                companyId: selectedCompanyId,
                appId: appId,
                start: start,
                end: end,
                appIds: appIds,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
        if (result.code && result.code === "failure") {
            throw result.message;
        }

        return result;
    },
};

export default SubService;
