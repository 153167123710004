import AWS from "aws-sdk";
import ApiService from "Services/apiService";

const AwsS3Service = {
    getAwsConfig: async () => {
        try {
            const credentials = await ApiService.putData({
                url: `/v2/credentials_react_app`,
            });

            return {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                region: credentials.region,
                docBucket: credentials.docBucket,
            };
        } catch (error) {
            console.error("Error fetching AWS config:", error);
            throw error;
        }
    },

    uploadDoc: async (key, body) => {
        try {
            const awsConfig = await AwsS3Service.getAwsConfig();
            AWS.config.update(awsConfig);
            const s3 = new AWS.S3();

            const params = {
                Bucket: awsConfig.docBucket,
                Key: key,
                Body: body,
            };

            const response = await s3.upload(params).promise();
            return response;
        } catch (error) {
            console.error("Error uploading file", error);
            throw error;
        }
    },

    deleteDoc: async (key) => {
        try {
            const awsConfig = await AwsS3Service.getAwsConfig();
            AWS.config.update(awsConfig);
            const s3 = new AWS.S3();

            const params = {
                Bucket: awsConfig.docBucket,
                Key: key,
            };
            const response = await s3.deleteObject(params).promise();
            return response;
        } catch (error) {
            console.error("Error deleting file", error);
            throw error;
        }
    },
};

export default AwsS3Service;
