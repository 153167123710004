import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { BootstrapDialog, BootstrapDialogTitle } from "../ui/BootstrapDialog";
import Grid from "@mui/material/Grid";
import SiteList from "./SiteList";
import SiteForm from "./SiteForm";
import SiteMap from "./SiteMap";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";

export default function SiteDialogue({ open, setOpen, selectedThingId, setReloadParent }) {
    const [selectedSite, setSelectedSite] = useState("");
    const [reload, setReload] = useState("");
    const [openSiteForm, setOpenSiteForm] = useState(false);
    const [position, setPosition] = useState([]);

    const handleClose = () => {
        setOpen(false);
        setOpenSiteForm(false);
        setReloadParent();
    };

    const callbackNew = () => {
        // setOpenSiteForm(!openSiteForm);
        // console.log("callbackNew....")
        setOpenSiteForm(true);
        setSelectedSite(undefined);
    };

    const handleDetail = (e) => {
        // console.log("handleDetail", e);
        setSelectedSite(e);
        setOpenSiteForm(true);
    };

    const handleCompleteSelected = (siteId) => {
        // console.log("handleCompleteSelected", siteId)
        setOpen(false);
        setOpenSiteForm(false);
        setReloadParent(siteId);
    };

    const handlePosition = (position) => {
        // console.log("handlePosition", position);
        setPosition(position);
    };

    const handleBackFromMap = () => {
        setOpenSiteForm(false);
        setPosition([]);
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={openSiteForm ? "md" : "sm"} fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Sites
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={0}>
                        <Grid item xs={openSiteForm ? 6 : 12} style={{ position: "relative" }}>
                            {!openSiteForm && (
                                <SiteList
                                    selectedThingId={selectedThingId}
                                    callbackDetail={(e) => handleDetail(e)}
                                    reload={reload}
                                    setReload={() => setReload(moment())}
                                    callbackNew={callbackNew}
                                    handleCompleteSelected={handleCompleteSelected}
                                />
                            )}
                            {openSiteForm && (
                                <Box style={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
                                    <Box
                                        onClick={handleBackFromMap}
                                        style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px", // Changed from left to right
                                            zIndex: 1001,
                                            backgroundColor: "white",
                                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                            borderRadius: "50%",
                                            width: "40px",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <ArrowBackIcon />
                                    </Box>
                                    <SiteMap selectedSite={selectedSite} zoom={13} callback={handlePosition} />
                                </Box>
                            )}
                        </Grid>
                        {openSiteForm && (
                            <Grid item xs={6}>
                                <SiteForm
                                    selectedSite={selectedSite}
                                    setReload={() => {
                                        setReload(moment());
                                        setOpenSiteForm(false);
                                        setPosition([]);
                                    }}
                                    position={position}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
