import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SliderCommon from "./SliderCommon";
import AttributeService from "./AttributeService";
import SuperviotConstants from "../SuperviotConstants";
import SliderTurbidite from "./SliderTurbidite";
import SliderPh from "./SliderPh";
import SliderRedox from "./SliderRedox";
import moment from "moment";
import CommSettings from "./CommSettings";
import Grid from "@mui/material/Grid";
import SliderNitrate from "./SliderNitrate";
import SliderConductivite from "./SliderConductivite";
import SliderO2 from "./SliderO2";

export default function SettingMain({ siteName, thingId }) {
    const [thing, setThing] = useState();
    const [attributes, setAttributes] = useState();
    const [reload, setReload] = useState();
    const [userDefined, setUserDefined] = useState();

    useEffect(() => {
        let isMounted = true;

        AttributeService.requestThing(thingId, (thingData) => {
            if (isMounted) setThing(thingData);
        }).then((modelId) => {
            if (!isMounted) return;

            AttributeService.requestAttributes(modelId).then((attributes) => {
                if (!isMounted) return;

                if (attributes) {
                    const filtered = attributes.filter((a) => a.name !== "Pression");
                    setAttributes(filtered);

                    const aIds = filtered.map((ele) => ele.id);
                    AttributeService.requestRanges(thingId, aIds, (data) => {
                        if (isMounted) setUserDefined(data);
                    });
                }
            });
        });

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, [thingId, reload]);

    const handleAttribute = (attributeId, trackColors, values, reverse) => {
        // console.log(attributeId, trackColors, values);
        AttributeService.updateRange(siteName, thingId, attributeId, trackColors, values, reverse).then(() => {
            setReload(moment().valueOf());
        });
    };

    return (
        <Box sx={{ flexGrow: 1, m: 2, gap: 2 }}>
            {/* <SliderCommon name="abc" defaultValue={[0, 45, 55, 65, 75, 100]} /> */}
            <Grid container spacing={1} sx={{ width: 900 }}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <CommSettings selectedThingId={thingId} />
                </Grid>
                <Grid item xs={12}>
                    {attributes &&
                        attributes.map((ele) => {
                            // console.log(ele);
                            // console.log(SuperviotConstants.default[ele.id]);

                            let preset = SuperviotConstants.default[ele.id];
                            if (!preset) {
                                // console.log("There are no default values for the " + ele.name);
                            } else {
                                if (userDefined && userDefined[ele.id]) {
                                    // console.log("------");
                                    preset["marks"] = userDefined[ele.id].marks;
                                    preset["value"] = userDefined[ele.id].value;
                                }

                                if (siteName === "FFATP" && !["Conductivité", "Redox", "Nitrates", "pH"].includes(ele.name)) {
                                    // Do not show the elements
                                    return null;
                                }
                                if (siteName === "FFATTG" && !["Conductivité", "Redox", "pH"].includes(ele.name)) {
                                    // Do not show the elements
                                    return null;
                                }
                                if (siteName === "FMPO" && !["Conductivité", "Redox", "pH"].includes(ele.name)) {
                                    // Do not show the elements
                                    return null;
                                }

                                if (ele.name.includes("Conductivité")) {
                                    return (
                                        <SliderConductivite
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else if (ele.name.includes("Nitrate")) {
                                    return (
                                        <SliderNitrate
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else if (ele.name.includes("Turbidité") || ele.name.includes("Turbidite")) {
                                    return (
                                        <SliderTurbidite
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else if (ele.name.includes("pH")) {
                                    return (
                                        <SliderPh
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else if (ele.name.includes("Redox")) {
                                    return (
                                        <SliderRedox
                                            name={ele.name}
                                            key={ele.id}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else if (ele.name.includes("O2")) {
                                    return (
                                        <SliderO2
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                } else {
                                    return (
                                        <SliderCommon
                                            key={ele.id}
                                            name={ele.name}
                                            defaultValue={preset.value}
                                            marks={preset.marks}
                                            max={preset.max}
                                            step={preset.step}
                                            attributeId={ele.id}
                                            callback={handleAttribute}
                                        />
                                    );
                                }
                            }
                        })}
                </Grid>
            </Grid>
        </Box>
    );
}
