export function DatabaseColumns(t) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "year",
            headerName: t("col.year"),
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "month",
            headerName: t("col.month"),
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "sizeByMonth",
            headerName: t("col.size") + " (MB)",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
            renderCell: (params) => {
                const sizeInMB = (params.value / (1024 * 1024)).toFixed(2);
                return (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {parseFloat(sizeInMB).toLocaleString()}
                    </div>
                );
            },
        },
    ];
}
