import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const LogService = {
    requestLogs: async (page, pageSize, setRowCount, sort, setRows, companyId, thingIds) => {
        setRows([]); // reset the rows

        let strSort = "";
        if (sort) {
            strSort = _.map(sort, (o) => {
                return (o.sort == "asc" ? "+" : "-") + o.field;
            }).join(",");
        }

        let filter = `company.id:eq_${companyId}`;
        const options = {
            url: `/api/logs`,
            params: {
                page,
                pageSize,
                sort: strSort,
                filter,
            },
        };

        if (thingIds) {
            options.params.filter += `,thing.id:in_${thingIds.join(",")}`;
        }

        return ApiService.getData(options)
            .then((response) => {
                // console.log(response);

                let data = [];

                response.data.forEach((ele) => {
                    let obj = {
                        id: ele.id,
                        type: ele.type,
                        message: ele.message,
                        entryDate: UtilService.formatMDYYHMS(ele.entryDate * 1000),
                        username: ele.username,
                        "thing.fixedname": ele.thing.fixedName,
                    };

                    data.push(obj);
                });

                setRowCount(response.totalSize);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default LogService;
