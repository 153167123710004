import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useTranslation } from "react-i18next";

export default function UserConfirm({ open, setOpen, changedValue, callback }) {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Dialog open={open} onClose={setOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                {/* <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("superviot.are_you_sure")} {changedValue} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            callback(true);
                            setOpen(false);
                        }}
                    >
                        {t('btn.yes')}
                    </Button>
                    <Button
                        onClick={() => {
                            callback(false);
                            setOpen(false);
                        }}
                        autoFocus
                    >
                        {t('btn.no')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
