import ApiService from "Services/apiService";

const SviChartService = {
    requestAttributes: async (thing, modelId, setAttributes) => {
        if (!modelId) {
            return;
        }

        let requestOptions = {
            url: `/api/models/${modelId}/attributes`,
        };

        return ApiService.getData(requestOptions).then((response) => {
            // console.log("/////-------");
            // console.log(response);
            // console.log(thing);

            let sorted = response.data;

            const exclusionLists = {
                SREINE: ["Pression"],
                FFATP: ["Pression", "Turbidite", "O2"],
                FFATTG: ["Pression", "Nitrates", "Turbidite", "O2"],
                FMPO: ["Pression", "Nitrates", "Turbidite", "O2"],
            };

            const exclusions = exclusionLists[thing.name] || [];

            sorted = sorted.filter((e) => !exclusions.includes(e.name));

            let nonBatteryElements = sorted.filter((element) => element.name !== "Battery");
            let batteryElements = sorted.filter((element) => element.name === "Battery");

            let reorderedArray = [...nonBatteryElements, ...batteryElements];

            sorted = reorderedArray;

            // console.log(sorted);
            setAttributes(sorted);
            // setAttributes(response.data);
        });
    },

    updateSiteDescription: async (thingId, siteId, siteDesc) => {
        let options = {
            url: `/v2/sites/coordinates`,
            data: {
                thingId,
                siteId,
                siteDesc,
            },
        };
        // console.log(options);

        return ApiService.putData(options)
            .then((r) => {
                // console.log(r);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default SviChartService;
