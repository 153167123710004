import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import IslandMap from "./island/IslandMap";
import SideManu from "./island/sidebar/SideManu";
import AppContext from "Components/AppContext";
import UserService from "Services/userService";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";

export default function SuperviotMain() {
    const [positions, setPositions] = useState();
    const [selectedPosition, setSelectedPosition] = useState();
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.updateTitle("Superviot");
    }, [ctx]);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            const requestFunction =
                user.authorities === "USER"
                    ? () => MeasurementServiceV2.requestByUser("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }], user.username)
                    : () => MeasurementServiceV2.requestData("", 0, 10, undefined, undefined, [{ field: "name", sort: "DESC" }]);

            requestFunction().then((r) => {
                // console.log("r", r);

                const mapped = r.map((item) => ({
                    name: item.name,
                    thingId: item.id,
                    siteName: item["site.name"],
                }));
                setPositions(mapped);
            });
        });
    }, [selectedPosition]);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2, display: "flex" }}>
            <IslandMap positions={positions} callback={(p) => setSelectedPosition(p)} />
            <SideManu position={selectedPosition} />
        </Box>
    );
}
